const PREFIX = 'DEBIE_FRONTEND_STORAGE_';

const fullName = suffix => PREFIX + suffix

let put = () => null;
let get = () => null;
let clear = () => null;

if (typeof window !== 'undefined') {
	put = (key, obj) => window.localStorage.setItem(fullName(key), JSON.stringify(obj))
	get = key => JSON.parse(window.localStorage.getItem(fullName(key)) || 'null')
	clear = key => window.localStorage.removeItem(fullName(key))
}


export default {
	put, get, clear
}