import { v4 as uuidv4 } from 'uuid';
import storage from "../lib/storage";
import settings from "../lib/settings";
import Analytics from 'analytics-node';


const ANON_ID_KEY = "SEGMENT_ANONYMOUS_ID_KEY";
const IS_DEBIE_STAFF_KEY = "IS_DEBIE_STAFF";

let node_analytics;
let anonymous_id;

export default {
	init: () => {
		if (!node_analytics && (!storage.get(IS_DEBIE_STAFF_KEY) || !settings.get('SKIP_SEGMENT_FOR_STAFF'))) {
			node_analytics = new Analytics(settings.get('SEGMENT_KEY'));
			anonymous_id = storage.get(ANON_ID_KEY)
			if (!anonymous_id) {
				anonymous_id = uuidv4();
			 	storage.put(ANON_ID_KEY, anonymous_id)
			}
			if (typeof window !== 'undefined') {
				window.i_am_debie_staff = () => {
					storage.put(IS_DEBIE_STAFF_KEY, true)
					if (storage.get(IS_DEBIE_STAFF_KEY)) {
						console.log('Yes, you are')
					} else {
						console.log('No, you are not')
					}
				}
			}
		}
	},
	identify: (traits={}) => {
		if (node_analytics) {
			node_analytics.identify({anonymousId: anonymous_id, traits});
		}
	},
	track: (event, properties={}) => {
		if (node_analytics) {
			node_analytics.track({anonymousId: anonymous_id, event, properties});
		}
		if (settings.get('CONSOLE_LOG_SEGMENT')) {
			console.log('Analytics event: ' + event)
		}
	},
	page: (page_name, properties={}) => {
		if (node_analytics) {
			node_analytics.page({anonymousId: anonymous_id, name: page_name, properties});
		}
		if (settings.get('CONSOLE_LOG_SEGMENT')) {
			console.log('Analytics page: ' + page_name)
		}
	}
}
