import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';

// Extension to Add Tab Select Behavior
class CustomPlacesAutocomplete extends PlacesAutocomplete {
  handleInputKeyDown = event => {
    switch (event.key) {
      case 'Enter':
        event.preventDefault()
        this.handleEnterKey()
        break
      case 'ArrowDown':
        event.preventDefault() // prevent the cursor from moving
        this.handleDownKey()
        break
      case 'ArrowUp':
        event.preventDefault() // prevent the cursor from moving
        this.handleUpKey()
        break
      case 'Escape':
        this.clearSuggestions()
        break
      case 'Tab':
        // event.preventDefault()
        this.handleEnterKey()
        break
      default:
        break;
    }
  }
}

const LocationSearchInput = ({onChange, onSelect, onReceiveStreet, onReceivePostalCode, onReceiveCity, onReceiveState, value, inputClassName }) => {

  const handleChange = address => {
    onChange(address)
  };

  const handleSelect = (address, place_id, suggestion) => {
    onSelect(address);
    let service = new  window.google.maps.places.PlacesService(document.getElementById('places-autocomplete-service-placeholder'));
    let request = {
        placeId: place_id,
        fields: ['address_components']
      };
    const callback = (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          let foundPostalCode = false;
          let foundCity = false;
          let foundState = false;
          let foundStreet = false;
          let foundRoute = false;
          let street = ''
          let route = ''
          let neighborhood = ''
          let area_admin_3 = ''
          if(place.address_components){
            // console.log(place.address_components)
            for(let component of place.address_components){
              if(component.types){
                for(let type of component.types){
                  if(type === "postal_code"){
                    foundPostalCode = true;
                    onReceivePostalCode(component.long_name);
                    break;
                  }
                  if(type === "locality"){
                    foundCity = true;
                    onReceiveCity(component.long_name);
                    break;
                  }
                  if(type === "neighborhood"){
                    neighborhood = component.long_name;
                    break;
                  }
                  if (type === "administrative_area_level_3"){
                    area_admin_3 = component.long_name;
                    break;
                  }
                  if(type === "administrative_area_level_1"){
                    foundState = true;
                    onReceiveState(component.short_name);
                    break;
                  }
                  if(type === "street_number"){
                    foundStreet = true;
                    street = component.long_name
                    break;
                  }
                  if(type === "route"){
                    foundRoute = true;
                    route = component.long_name
                    break;
                  }
                }
              }
              if(foundPostalCode && foundCity && foundState && foundStreet && foundRoute){
                break;
              }
            }
            if(!foundPostalCode){
              onReceivePostalCode("");
            }
            if(!foundCity){
              if (neighborhood){
                onReceiveCity(neighborhood);
              }else if (area_admin_3){
                onReceiveCity(area_admin_3)
              }else {
                onReceiveCity("");
              }
            }
            if(!foundState){
              onReceiveState("");
            }
            if(foundStreet){
              onReceiveStreet(foundRoute ? `${street} ${route}`:`${street}`);
            }else{
              onReceiveStreet("")
            }

          }
        }
    }
    service.getDetails(request, callback);
  };

  const searchOptions = {
    componentRestrictions: {country: "us"}
  };

    return (
      <CustomPlacesAutocomplete
        value={value}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: `location-search-input ${inputClassName}`,
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion,suggestionIndex) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={suggestionIndex}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
              <span id="places-autocomplete-service-placeholder"></span>
            </div>
          </div>
        )}
      </CustomPlacesAutocomplete>
    );
  }

export default LocationSearchInput;
